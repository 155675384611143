<template>
  <div class="xw">
    <div class="center">
      <div class="center_top">
        <span class="xwfb">发布新闻</span>
      </div>
      <div>
        <!-- 新闻标题 -->
        <div class="xwbt">
          <div class="xwbt_top">
            <span class="red">*</span>
            <span>新闻标题</span>
          </div>
          <textarea
            maxlength="50"
            minlength="5"
            style="resize: none;"
            v-model="title"
            placeholder="文章标题（最少5个字）"
          ></textarea>
          <span class="font-num" v-if="title">{{ title.length }}/50</span>
          <span class="font-num" v-if="!title">0/50</span>
        </div>
        <!-- 新闻内容 -->
        <div class="xwnr">
          <div class="xwbt_top">
            <span class="red">*</span>
            <span>新闻内容</span>
          </div>
          <editor-bar class="font-siz" v-model="content" :isClear="isClear"></editor-bar>
        </div>
        <!-- <div class="tj"><span class="blue"></span>添加优质配图将会得到更多人关注</div> -->
        <!-- 上传图片 -->
        <div class="border">
          <span class="reda">*</span>
          <el-upload
            :action="surl"
            accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .JPG, .JPEG, .PBG"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="PICsuccess"
            :limit="1"
            class="Imege"
            :file-list="fileList1"
          >
            <i class="up-img"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img :src="dialogImageUrl" alt class="width_height" />
          </el-dialog>
          <span class="tj">
            <span class="blue"></span>添加优质配图将会得到更多人关注
          </span>
        </div>
        <!-- 发布 -->
        <div class="fb">
          <el-button type="primary" @click="fb()">发布</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import EditorBar from "@/components/wangEnduit/index";
// import { ElFooter } from 'node_modules/element-ui/types/footer';
export default {
  data() {
    return {
      title: "", //新闻标题
      content: "", //新闻内容
      isClear: false,
      surl: "", //路径
      fileList1: [],
      dialogVisible: false,
      dialogImageUrl: "" //封面图片
    };
  },
  created() {
    this.surl = this.api.LoginURL.concat("/resources/trading/resourcesUpload");
  },
  components: { EditorBar },
  methods: {
    //  發佈
    async fb() {
      if (this.title == "") {
        this.$message({
          message: "标题不能为空哦！",
          type: "warning"
        });
      } else if (this.content == "") {
        this.$message({
          message: "内容不能为空哦！",
          type: "warning"
        });
      } else if (this.dialogImageUrl == "" || this.fileList1 == "") {
        this.$message({
          message: "图片不能为空哦！",
          type: "warning"
        });
      } else {
        await axios
          .post(
            this.api.LoginURL.concat("/common/yiqipush"),
            {
              title: this.title, //標題
              content: this.content, //內容
              img: this.api.LoginURL.concat() + this.dialogImageUrl //圖片
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
          .then(response => {
            console.log("111", response);
            if (response.data.success == true) {
              this.$message({
                message: "发布成功！",
                type: "success"
              });
              (this.title = ""),
                (this.content = ""),
                (this.dialogImageUrl = ""),
                (this.fileList1 = []); //清空
            } else if (response.data.success == false) {
              this.$message.error("发布失败！");
            }
          })
          .catch(function(error) {
            console.log("错误" + error);
          });
      }
    },
    handlePreview(file, fileList) {
      console.log(fileList);
      this.form.name = file.data.fileName + "." + file.data.type;
      this.form.file = file.data.url;

      this.fileList[0] = fileList;
      this.fileList[0].url = file.data.url;
    },
    handleRemove(file, fileList) {
      this.fileList1 = [];
      this.dialogImageUrl = []; //请空
      console.log(file, fileList);
    },
    //上传图片成功后返回的数据（图片）
    PICsuccess(file, fileList) {
      this.dialogImageUrl = file.data.url;
      console.log("上传返回的图片路径：" + this.dialogImageUrl);
      this.fileList1[0] = fileList;
      this.fileList1[0].url = this.api.LoginURL.concat(file.data.url);
    },
    //点击文件列表中已上传的文件时的钩子（图片）
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
};
</script>
<style scoped>
.up-img:hover {
  background-color: rgba(210, 210, 210, 1);
}
.Imege {
  display: inline-block;
  width: 3rem;
  height: 1.1rem;
  position: relative;
  /* left: 10px; */
  /* border: 1px solid red; */
}
.up-img {
  margin-left: 10px;
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border: 1px solid rgba(210, 210, 210, 1);
  border-radius: 3px;
  background-image: url(~@/assets/icon/add-img.png);
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position-x:21px;
  background-position-y: 20px;
  position: relative;
  left: -30px;
  top: -10px;
  background-color: white;
  cursor: pointer;
  /* border: 1px solid red; */
}
.xw {
  /* display: inline-block; */
  margin-left: 100px;
  margin-top: 50px;
  width: 850px;
  /* border: 1px solid red; */
  background-color: rgb(255, 255, 255);
  padding-top: 50px;
  padding-bottom: 50px;
}
.center {
  /* margin-top:50px ; */
  width: 700px;
  margin: auto;
  /* border: 1px solid red; */
}
.center_top {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  display: inline-block;
  /* border: 1px solid red; */
  text-align: center;
  /* padding-bottom: 10px; */
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 4px;
}
.xwfb {
  padding-bottom: 6px;
  border-bottom: 2px solid hsla(209, 100%, 50%, 1);
}
.red {
  color: red;
  font-size: 15px;
  margin-right: 5px;
  display: inline-block;
}
.reda {
  color: red;
  font-size: 15px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: 0.5rem;
  left: 0.1rem;
}
.xwbt {
  width: 100%;
  display: inline-block;
  /* border: 1px solid red; */
  font-weight: bold;
  position: relative;
  margin-top: 50px;
}
.xwbt_top {
  width: 100%;
  display: inline-block;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
textarea {
  padding: 0;
  border: 0;
  margin: 0;
}
textarea {
  width: 98.4%;
  height: 60px;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #dcdcdc;
}
textarea:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
  /* border-width :0 0 1px 0 ; */
}
.font-num {
  position: absolute;
  bottom: 7px;
  right: 8px;
}
.xwnr {
  margin-top: 50px;
}
.fb {
  float: right;
}
.border {
  width: 700px;
  margin-top: 35px;
  margin-bottom: 45px;
  display: inline-block;
  border: 1px solid #dcdcdc;
  height: 1.2rem;
  position: relative;
}
.blue {
  width: 5px;
  height: 5px;
  background: #2c87e2;
  border-radius: 50%;
  display: inline-block;
  border: 0;
  margin-right: 3px;
  position: relative;
  top: -3px;
}
.tj {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #303030;
  /* border: 1px solid green; */
  position: relative;
  top: 0.5rem;
  /* height: 110px; */
  /* padding-top: 50px; */
  /* line-height: 110px; */
  /* display: inline-block; */
}
.width_height {
  /* width: 52px;
  height: 52px;
  display: inline-block; */
  /* border:1px solid red */
}
.font-siz {
  font-size: 13px;
}
.el-upload--picture-card{
  background-color: blue;
}
</style>